.chart-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.sliding-sidebar-container {
  transition: transform 0.3s ease;
  display: flex;
  z-index: 2;
  position: absolute;
  /* top: 49px; */
  top: 52px;
  right: 0;
  /* right: 12px; */
}

.toggle-btn-sidebar {
  background: #fbf9f4;
  width: 30px; /* Adjust width as needed */
  height: calc(100vh - 94px);
  color: white;
  border: none;
  transition: transform 0.3s ease;
  position: relative;
  right: 0;
  display: flex;
  justify-content: center;
}


.toggle-arrow-btn {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #3262C7;
  cursor: pointer;
  width: 20px;
  height: 35px;
  border: 2px solid #3262C7;
  margin-top: 10px;
}

.toggle-btn-sidebar.none {
  display: none;
}

.toggle-btn-sidebar.open {
  transform: translateX(-282px);
  display: none;
}

.sidebar-content {
  width: 250px;
  transform: translateX(288px);
  transition: transform 0.3s ease;
}

.sidebar-content.none {
  display: none;
}

.sidebar-content.open {
  transform: translateX(0);
}

.chart-details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 40px;
  box-shadow: 0 0 10px grey;
  background-color: #fff;
  border-bottom: 1px solid #e2e2e2;
}

.chart-details-title {
  font-size: 14px;
  border-radius: 0;
  font-weight: 400;
  color: #002677;
}

.chart-details-toggle {
  height: 16px;
  width: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
}

.chart-details-title div {
  justify-self: center;
}

.chart-details-group {
  width: 250px;
}

.member-status {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.member-info {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #002677
}

/*TODO: going to have to space the details groups much differently to get the following border to work:*/
/*.chart-details-group:first-child {*/
/*    border-right: 1px solid gray;*/
/*}*/

.chart-details-group h3 {
  margin-top: 8px;
  margin-bottom: 8px;
}

dl {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10ch, 1fr));
  margin-top: 8px;
  /*grid-gap: 1em;*/
}

dt {
  grid-column-start: 1;
  font-size: 13px;
}

dd {
  margin: 0;
  grid-column-start: span 1;
  font-size: 13px;
}

.thumbnail-renderer .image-wrapper {
  position: absolute;
  text-align: center;
}
.thumbnail-renderer img {
  border: 1px solid #6D6F77;
}
.thumbnail-renderer .label {
  font-size: 18px;
  margin-top: -29px;
}
.thumbnail-renderer .newLabel {
  position: absolute;
  font-size: 11px;
  background: #0F0F59;
  color: #fff;
  padding: 1px 1px;
  left: 49px;
  top:5px
}
.thumbnail-renderer .appended {
  position: absolute;
  top: 120px;
  font-size: 15px;
  color: #0F0F59;
}

.unknownpages-details {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-icon {
  display: flex;
}
