.chart-nav {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow-y: auto;
}

.chart-nav.right-side {
    /* The right side chart navs need more room at the bottom because of the survey button */
    padding-bottom: 28px;
}

.chart-nav h2 {
    margin-top: 0;
}

.nav-matches-container {
    flex: 1;
    overflow-y: auto;
    margin-top: 4px;
}

.nav-matches-container .matches, .all-pages-nav-matches .matches {
    /*max-height: 228px;  !* hard-wired, does it need to be? Sized to show 6 matches *!*/
    /*overflow: scroll;*/
}

.keywords-guide-pane.disabled {
    opacity: var(--app-disabled-opacity);
}

.nav-match {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin-top: 8px;
    padding: 8px;
    background-color: white;
    color: black;
    border-radius: 2px;
    cursor: pointer;
}

.nav-match.all-pages {
    padding: 0 8px 0 0;
}

/*make the entire region of the item clickable to toggle reviewed checkbox*/
.nav-match label {
    padding: 8px 0 8px 8px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 190px;
}

.nav-match.reviewed {
    background-color: #CCEFFF;
}

.nav-match:hover {
    border: 2px solid #4CC6FF;
}

.nav-match .match-count {
    font-style: italic;
}

.no-matches {
    margin-top: 12px;
}

.nav-match.selected {
    background-color: #CCEFFF;
    border: 2px solid #4CC6FF;
    padding: 6px;
}

.reviewed-item {
    position: relative;
    margin: 8px 0;
    cursor: pointer;
    padding-left: 8px;
    /*border-left: 1px dashed #FFFFFF;*/
}

.reviewed-item.selected {
    color: var(--app-highlight-color);
}

/* The following was an attempt at the fancier dashed line left border */
/*.reviewed-item:after {*/
/*    position: absolute;*/
/*    content: '';*/
/*    width: 7px;*/
/*    height: 9px;*/
/*    top: -5px;*/
/*    left: -1px;*/
/*    border-left: 2px solid blue;*/
/*}*/


.reviewed-item .reviewed-when {
    font-size: var(--small-text);
    font-style: italic;
}

.reviewed-item .reviewed-icon {
    position: absolute;
    width: 8px;
    height: 8px;
    top: 6px;
    left: -5px;
}

.chart-nav-tooltip-icon {
    margin-left: 6px;
    margin-top: 5px;
    font-size: 13px;
}

.chart-nav-tooltip {
    /*overrides of the default react tooltip styles have to be !important*/
    border-radius: 2px !important;
}

.side-nav-dropdown {
    position: relative;
    margin-bottom: 2px;
    font-size: var(--small-text);
}

.side-nav-dropdown ul {
    position: absolute;
    top: 7px;
    left: 46px;
    background-color: white;
    color: black;
    list-style: none;
    border-radius: 3px;
    border: none;
    z-index: 100;
    padding-inline-start: 0;
    box-shadow: 0 4px 4px 0 #000000;
}

.side-nav-dropdown ul:focus {
    outline: none;
}

.side-nav-dropdown ul li {
    padding: 7px 12px 7px 2px;
}

.side-nav-dropdown label {
    margin-right: 5px;
}

.side-nav-dropdown img {
    vertical-align: middle;
    height: 5px;
    margin-left: 4px;
}

.side-nav-dropdown ul li img {
    width: 10px;
    height: 10px;
    margin: 0;
}

.side-nav-dropdown .menu-select {
    display: inline-block;
    min-width: 24px;
}

.side-nav-dropdown button {
    background-color: var(--app-primary-color);
    color: white;
    font-size: 11px;
}

/* TODO: DRY this out, it's pretty much just .confirm-member-match in member-verification.css */
.feedback-container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.legend-button{
    background: #fff;
}

.feedback-button {
    width: 100%;
    /*color: var(--app-primary-text-color);*/
    /*background-color: var(--app-background-color);*/
    height: 54px;
    /*border-radius: 2px;*/
    /*border-top: 1px solid;*/
    font-size: var(--small-text);
    font-weight: bold;
}

.close-sidebar{
    text-decoration:underline;
    color: #3262C7;
    font-size:14px;
    font-weight: 700
}

.close-button {
    cursor:pointer;
    padding:10px;
    color:#3262C7
}

.search-result-count {
    color: #002677;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
}

.tab-panel-text {
    text-transform: none;
    font-weight: bold;
    font-size: 16px;
}