.chart-page-load {
  position: sticky;
  top: 50%;
  left: 50%;
  z-index: 100;
}
.dnone{
  display: none;
}

.full-screen-btn {
  border: none;
  background: inherit;
  padding: 5px;
  margin-right: 10px;
  color: #3262C7;
}

.fullscreen-text{
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FBF9F4;
}

.f-none {
  display: none;
}

.chart-details-info{
  height: 36px;
  padding: 0 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}