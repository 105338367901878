.legend-section {
    margin-left: 1px;
    /* margin-bottom: 20px; */
}

.keywords-entry-view{
    border-bottom: 1px solid #e2e2e2;
}

.keyword-entry {
    all: unset;
    display: block;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 0 8px 32px;
    margin-bottom: 4px;
    cursor: pointer;
}

.keyword-entry.disabled {
    cursor: inherit;
}

.legend-entry {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: normal;
}

.legend-header {
    margin-bottom: 8px;
    font-weight: bold;
    border-bottom: 1px solid #FFFFFF
}

.keyword-header {
    all: unset;
    font-weight: 400;
    font-size: 14px;
    /*display: inline-block;*/
    /*margin-bottom: 8px;*/
    /*cursor: pointer;*/
    /*margin-top: 4px;*/
    width: 100%;
}

.keyword-header.disabled {
    cursor: inherit;
}

.keyword-entry:hover:not(.disabled), .keyword-header:hover:not(.disabled) {
    color: var(--app-highlight-color);
    font-weight: bold;
}

.unknown-pages {
    background-color: var(--app-warning-red);
    padding: 2px;   /* need to "shrink" the exclamation mark within the circle a little bit */
    border-radius: 7px;
}

.MuiAccordion-region .css-5dcw8b-MuiAccordionDetails-root {
    padding: 0;
}

.css-1xghwer-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px solid #e2e2e2;
}