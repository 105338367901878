@font-face {
  font-family: 'core_sans_c45_regular';
  src: url('../fonts/coresansc_45regular_macroman/coresansc45-webfont.eot');
  src: url('../fonts/coresansc_45regular_macroman/coresansc45-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/coresansc_45regular_macroman/coresansc45-webfont.woff2') format('woff2'),
  url('../fonts/coresansc_45regular_macroman/coresansc45-webfont.woff') format('woff'),
  url('../fonts/coresansc_45regular_macroman/coresansc45-webfont.ttf') format('truetype'),
  url('../fonts/coresansc_45regular_macroman/coresansc45-webfont.svg#core_sans_c45_regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

