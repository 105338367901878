.chart-nav-search {
    flex: 1 1;
    max-width: var(--search-bar-width);
    color: black;
    position: relative;
    margin-top: 4px;
    margin-right: 16px;
}

.chart-nav-search form.dimmed {
    /*opacity: var(--app-disabled-opacity); see: https://jira.healthcareit.net/browse/AIHEDIS-253 */
}

.chart-nav-search input {
    width: 100%;
    padding: 10px 30px 10px 24px;
    display: block;
    height: 32px;
}

.magnifier-icon {
    position: absolute;
    top: 4px;
    right: 8px;
    color: #3262C7;
    font-size: 14px;
}

.clear-search-icon {
    position: absolute;
    top: 7px;
    right: 14px;
    /* color: var(--app-dark-text-color); */
    color: #3262C7;;
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.search-form-keyword-suggestions {
    position: absolute;
    top: 30px;
    z-index: 100;
    background-color: white;
    width: 100%;
    padding-inline-start: 0;
    box-shadow: 0 2px 5px #e3e5e6;
}

.search-form-keyword-suggestion {
    list-style-type: none;
    border-bottom: 1px solid #e3e5e6;
    padding: 12px 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tier > * {
    display: inline-block;
}

.tier img {
    margin-right: 11px;
    vertical-align: top;
    margin-left: 4px;
    margin-top: 2px;
}

.tier-suggestion {
    margin-bottom: 4px;
    color: #0F0F59;
    font-weight: 600;
}

.tier-keywords {
    margin-left: 2px;
    font-size: .9em;
}

.search-form-no-match {
    color: #F42A4D;
    font-style: italic;
}

.no-match-keyword {
    font-weight: bold;
}
