@import './fonts';
@import './_variables.scss';

body {
  margin: 0;
  font-family: $font-core-sans;
  font-size: $font-size-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

input, select, textarea, button {
  font-family:inherit;
}

code {
  font-family: $font-source-code-pro;
}
