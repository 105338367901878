.chart-wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  background-color: #e2e2e2;
  /* padding: 0px 12px 12px 12px; */
  position: relative;
}

.toolbar-container {
  display: flex;
  flex-direction: row;
  height: var(--chart-page-controls-height);
  background-color: var(--chart-page-controls-background);
  /* filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25)); */
  border: 1px solid #e2e2e2;
  z-index: 2; /* must do this to render dropshift dropdown above the chart viewer */
}

.side-bar {
  /*flex: 0 0 var(--chart-nav-width);*/
  /* background-color: var(--app-primary-color); */
  /*border-top: 1px solid #3e3e7a;*/
  /*color: white;*/
  /*position: relative;     !* so the toggler can be positioned absolutely *!*/
  /*padding: 20px 0 20px;*/

  /* flex the side bar so children can fill entire height, and grow the sub-panes to fill available space */
  display: flex;
  flex-direction: column;
  /* have to set a fixed height on this to get sub-panes to overflow-y within their parents */
  width: 250px;
  height: calc(100vh - 94px);
  background-color: #fbf9f4;
  top: '2px';
}

.suspicious {  
  height: calc(100vh - 130px);  
}


.side-bar.guide {
  flex: 0 0 var(--abstractor-guide-width);
}

/*.side-bar > * {*/
/*    padding: 0 16px 16px;*/
/*}*/

.side-bar.collapsed {
  flex: 0 0 var(--chart-nav-width-collpsed);
}

/*commented-out, this should now be within CollapsibleColumn component*/
/*.side-bar .side-bar-toggle {*/
/*    position: absolute;*/
/*    top: 20px;*/
/*    right: 10px;*/
/*    padding: 4px;*/
/*    font-size: 1.2em;*/
/*    cursor: pointer;*/
/*}*/

/*.side-bar.collapsed .side-bar-toggle {*/
/*    right: 4px;*/
/*}*/

.side-bar-sub-component {
  background-color: rgba(255, 255, 255, 0.15);
  position: relative;
  flex: 1 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.side-bar-sub-component:last-child {
  /* more room to flex grow at the bottom */
  margin-bottom: 0;
}

.side-bar-sub-component.no-grow {
  flex: 0;
  overflow-y: unset;
}

.side-bar-sub-component.keywords-guide {
  flex: 3 1;
}

.side-bar-sub-component.keywords-guide.no-grow {
  flex: 0;
}

.side-bar-sub-component.legend {
  flex: 2 1;
}

.side-bar-sub-component.legend.no-grow {
  flex: 0;
}

.side-bar-sub-component-toggle {
  position: absolute;
  padding: 4px;
  right: 12px;
  top: 18px;
  font-size: 16px;
  cursor: pointer;
}

.side-bar-sub-component h3 {
  margin-top: 0;
}

.side-bar-sub-component h3.collapsed {
  margin: 0;
}
