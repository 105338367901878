:root {
  /* Put application-wide css constants here: */

  /* dimensions */
  --min-app-width: 1090px;
  --title-bar-height: 48px;
  --title-bar-no-search-height: 50px;
  --chart-nav-width: 280px;
  --abstractor-guide-width: 260px;
  --chart-nav-width-collpsed: 30px;
  --chart-page-controls-min-width: 540px;
  --chart-page-controls-max-width: 640px;
  --chart-page-controls-height: 40px;
  --chart-page-controls-background: #FBF9F4;
  --search-bar-width: 900px;

  /* font sizes */
  --extra-large-text: 20px;
  --large-text: 16px;
  --normal-text: 14px;
  --small-text: 13px;

  /* colors */
  --app-primary-color: #0f0f59;
  --app-background-color: #f4f6f9;
  --app-highlight-color: #4cc6ff;
  --app-primary-text-color: #0e75a5;
  --app-dark-text-color: #37474f;
  --app-warning-red: #f53057;
  --app-success-green: #358600;
  --keyword-excluded-color: #fc5371;

  /* disabled elements */
  --app-disabled-opacity: 30%;
}

/*TODO: figure out which of these, if any, really need to be 100 view height*/
html {
  height: 100vh;
}

body {
  height: 100vh;
  min-width: var(--min-app-width);
}

root {
  height: 100vh;
}

.chart-viewer-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  display: flex;
  flex: 1 1;
}

/* global font styles */

/* h2 elements are used to style what are called "nav headers" in the figma design */
h2 {
  font-size: var(--large-text);
}

/* h3 elements are used to style what are called "section headers" in the figma design */
h3 {
  font-size: var(--normal-text);
}

/* h4 elements are used to style what I call "section sub-headers". They are the same size as h3, which maybe
   I should bring up with design */
h4 {
  font-size: var(--normal-text);
}

.status-text-x-large {
  font-size: var(--extra-large-text);
}

.status-text-large {
  font-size: var(--large-text);
  font-weight: 400;
  color:#323334;
}

.small-text {
  font-size: var(--small-text);
}

.disclaimer-text {
  color: #4cc6ff;
  font-style: italic;
}

/* Other "global" styles */
.hidden {
  display: none;
}

.flex-center {
  display: flex;
  align-items: center;
}

.vertical-flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex: 1 1;
}

.icon-medium {
  height: 14px;
  width: 14px;
  margin-right: 10px;
}

.icon-large {
  height: 14px;
  width: 14px;
}

.large-warning-icon {
  height: 25px;
  width: 25px;
  background-color: var(--app-warning-red);
  padding: 4px;
  border-radius: 16px;
  margin-right: 16px;
}

.small-warning-icon {
  height: 20px;
  width: 20px;
  background-color: var(--app-warning-red);
  padding: 4px;
  border-radius: 10px;
}

.small-success-icon {
  height: 16px;
  width: 16px;
  color: var(--app-success-green);
  /*border-radius: 10px;*/
}

/* default dropdown styles */

.default-dropdown {
  position: relative;
  margin-bottom: 2px;
  font-size: var(--small-text);

  label {
    margin-right: 5px;
  }

  img {
    vertical-align: middle;
    height: 5px;
    margin-left: 4px;
  }

  ul {
    position: absolute;
    top: 7px;
    left: 46px;
    background-color: white;
    color: black;
    list-style: none;
    border-radius: 3px;
    border: none;
    z-index: 100;
    padding-inline-start: 0;
    box-shadow: 0 4px 4px 0 #000000;

    &:focus {
      outline: none;
    }

    li {
      padding: 7px 12px 7px 2px;

      img {
        width: 10px;
        height: 10px;
        margin: 0;
      }
    }
  }

  .menu-select {
    display: inline-block;
    min-width: 24px;
  }

  button {
    background-color: var(--app-primary-color);
    color: white;
    font-size: 11px;
  }
}

/* end default dropdown styles */

.svg-button {
  border: none;
  background: inherit;
  padding: 4px;
  height: 32px;
  width: 34px;

  &:disabled {
    opacity: var(--app-disabled-opacity);
  }
}
